@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/sunday-burger');

body {
  
  background-color: #0A591C;
}

.burger{
  font-family: 'Sunday Burger', sans-serif;
                                                
}